document.addEventListener('DOMContentLoaded', function () {
	toggleGnav();
	smoothScroll();
})
window.addEventListener('load', function () {

})
window.addEventListener('scroll', function () {
});


function smoothScroll() {
	$('a[href^="#"]').click(function () {
		// スクロールの速度
		var speed = 500; // ミリ秒
		// アンカーの値取得
		var href = $(this).attr("href");
		// 移動先を取得
		var target = $(href == "#" || href == "" ? 'html' : href);
		// 移動先を数値で取得
		var position = target.offset().top;
		// スムーススクロール
		$('body,html').animate({ scrollTop: position }, speed, 'swing');
		return false;
	});
}
function toggleGnav() {
	$('.js-menu').click(function () {
		$(this).toggleClass('is-open')
		$('.js-gnav').toggleClass('is-open')
		return false;
	})
	$('.js-togglechild').click(function () {
		$(this).toggleClass('is-open');
		$(this).next('.child').stop().slideToggle();
		return false;
	})
}

window.MTNetFormDataLayer = window.MTNetFormDataLayer || [];
function MTNetForm() {
	MTNetFormDataLayer.push(arguments);
}
MTNetForm("validator",
	[
		{
			validator: function (values) {
				var email, email2, email3, confirm, confirm2, confirm3, confirmId, confirmId2, confirmId3;
				values.forEach(function (v) {
					if (v.label === "メールアドレス") {
						email = v.value;
					} else if (v.label === "メールアドレス（確認用）") {
						confirm = v.value;
						confirmId = v.id;
					}
				});

				if (email && confirm && email !== confirm) {
					return {
						id: confirmId,
						message: "「メールアドレス（確認用）」が一致していません。",
					};
				}

				values.forEach(function (v) {
					if (v.label === "登録中のメールアドレス") {
						email2 = v.value;
					} else if (v.label === "登録中のメールアドレス（確認用）") {
						confirm2 = v.value;
						confirmId2 = v.id;
					}
				});

				if (email2 && confirm2 && email2 !== confirm2) {
					return {
						id: confirmId2,
						message: "「登録中のメールアドレス（確認用）」が一致していません。",
					};
				}

				values.forEach(function (v) {
					if (v.label === "変更後のメールアドレス") {
						email3 = v.value;
					} else if (v.label === "変更後のメールアドレス（確認用）") {
						confirm3 = v.value;
						confirmId3 = v.id;
					}
				});

				if (email3 && confirm3 && email3 !== confirm3) {
					return {
						id: confirmId3,
						message: "「変更後のメールアドレス（確認用）」が一致していません。",
					};
				}
			},
		},
		{
			id: "8ed1bfd7-6292-4a6a-bc64-aa9ab7e6a63c",
			validator: function (value) {
				var dfd = $.Deferred();
				setTimeout(function () {
					if (value.length > 101) {
						dfd.resolve("100文字以内で入力してください");
					} else {
						dfd.resolve();
					}
				}, 100);
				return dfd.promise();
			},
		},
		{
			id: "a1cacdec-bda5-4ea8-a476-38a156c08059",
			validator: function (value) {
				var dfd = $.Deferred();
				setTimeout(function () {
					if (value.length > 101) {
						dfd.resolve("100文字以内で入力してください");
					} else {
						dfd.resolve();
					}
				}, 100);
				return dfd.promise();
			},
		},
		{
			id: "ee61bd90-f710-4f2e-9d81-a6c2e366cbdb",
			validator: function (value) {
				var dfd = $.Deferred();
				setTimeout(function () {
					if (value.length > 101) {
						dfd.resolve("100文字以内で入力してください");
					} else {
						dfd.resolve();
					}
				}, 100);
				return dfd.promise();
			},
		},
		{
			id: "e56419cf-ddff-4795-b435-8ea119f41763",
			validator: function (value) {
				var dfd = $.Deferred();
				setTimeout(function () {
					if (value.length > 101) {
						dfd.resolve("100文字以内で入力してください");
					} else {
						dfd.resolve();
					}
				}, 100);
				return dfd.promise();
			},
		},
	]
);
